<template>
  <div class="card-wrap">
    <h3 class="p-mb-4">
      <!-- <Icon v-if="icon">
        <template #component>
          <img :src="iconPath" class="box-icon" />
        </template>
      </Icon> -->
      {{ formDef.label }}
    </h3>
    <slot />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import FormSpecDef from "@/model/forms/formSpecDef";

@Options({
  props: {
    formDef: Object as () => FormSpecDef
  }
})
export default class DBox extends Vue {
  formDef!: FormSpecDef;
}
</script>

<style scoped>
.card-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-wrap h3 {
  font-weight: bold;
  font-size: 20px;
  border-bottom: 2px solid black;
}

.card-wrap .box-icon {
  width: 30px;
  margin-bottom: 7px;
}
</style>

import { VuexModule, Module, Mutation } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class NavMenu extends VuexModule {
  visible = true;

  @Mutation
  toggle() {
    this.visible = !this.visible;
  }
}

<template>
  <div class="menu" ref="nav">
    <ul>
      <li
        v-for="(module, i) in accesed"
        :key="i"
        :class="module.name.toLowerCase()"
      >
        <router-link :to="{ name: module.toName }" class="icon">
          {{ capitalize(module.name) }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AccessRights from "@/model/enums/accessRights";
import { namespace } from "vuex-class";
const navMenu = namespace("navMenu");

@Options({
  watch: {
    visible: {
      handler: "toggle"
    }
  }
})
export default class Navigation extends Vue {
  $refs!: {
    nav: HTMLFormElement;
  };
  @navMenu.State
  visible!: boolean;

  accessRights: AccessRights[] = [
    AccessRights.PATIENT_STACK
    // AccessRights.ATA_REGISTRY
  ]; //TODO From vuex
  private modules: {
    name: string;
    toName: string;
    acces: AccessRights | boolean;
  }[] = [
    { name: "home", toName: "dashboard", acces: true },
    {
      name: "patients",
      toName: "patient-list",
      acces: AccessRights.PATIENT_STACK
    },
    { name: "register", toName: "test", acces: AccessRights.ATA_REGISTRY },
    { name: "institutions", toName: "dashboard", acces: false },
    { name: "modulegroup", toName: "dashboard", acces: false },
    { name: "users", toName: "dashboard", acces: AccessRights.ATA_USER },
    { name: "rolegroup", toName: "dashboard", acces: false },
    {
      name: "databasestatus",
      toName: "dashboard",
      acces: AccessRights.DATABASE_ADMIN
    },
    { name: "donors", toName: "dashboard", acces: AccessRights.DONOR_STACK },
    { name: "import", toName: "dashboard", acces: AccessRights.IMPORTS },
    { name: "importhistory", toName: "dashboard", acces: AccessRights.IMPORTS },
    { name: "export", toName: "dashboard", acces: AccessRights.EXPORTS },
    { name: "exporthistory", toName: "dashboard", acces: AccessRights.EXPORTS },
    {
      name: "searchhistory",
      toName: "dashboard",
      acces: AccessRights.SEARCH_COMMANDS
    },
    { name: "notifications", toName: "dashboard", acces: false }
  ];

  mounted() {
    this.toggle();
  }

  //TODO
  get accesed() {
    return this.modules.filter(module => {
      return (
        module.acces === true ||
        (module.acces !== false && this.accessRights.includes(module.acces))
      );
    });
  }

  capitalize(t: string) {
    return t.charAt(0).toUpperCase() + t.slice(1);
  }

  toggle() {
    if (this.visible) {
      this.$refs.nav.style.width = "200px";
    } else {
      this.$refs.nav.style.width = "0";
    }
  }
}
</script>

<style scoped>
.menu {
  border-right: 1px solid #e3e2e6;
  border-bottom: 0;
  border-top: 0;
  border-left: 0;
  /* width: 310px; */ /* Set in toogle() */
  float: left;
  font-family: Tahoma;
  overflow: hidden;
  transition: 0.5s;
}

.menu img {
  height: 50px;
  width: 25px;
  -webkit-background-size: 25px 25px;
  background-size: 25px 25px;
  vertical-align: middle;
  float: left;
  padding: 0 15px 0 10px;
}

/****ICONS:hover***/
.home .icon:before {
  background: url("../assets/gfx/menu-home.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.home .icon:hover:before {
  background: url("../assets/gfx/d-menu-home.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.patients .icon:before {
  background: url("../assets/gfx/menu-bed.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.patients .icon:hover:before {
  background: url("../assets/gfx/d-menu-bed.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.register .icon:before {
  background: url("../assets/gfx/menu-globe.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.register .icon:hover:before {
  background: url("../assets/gfx/d-menu-globe.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.institutions .icon:before {
  background: url("../assets/gfx/menu-institution.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.institutions .icon:hover:before {
  background: url("../assets/gfx/d-menu-institution.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.modulegroup .icon:before {
  background: url("../assets/gfx/menu-cluster.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.modulegroup .icon:hover:before {
  background: url("../assets/gfx/d-menu-cluster.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.users .icon:before {
  background: url("../assets/gfx/menu-group.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.users .icon:hover:before {
  background: url("../assets/gfx/d-menu-group.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.rolegroup .icon:before {
  background: url("../assets/gfx/menu-user-card-role-groups.svg") center
    no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.rolegroup .icon:hover:before {
  background: url("../assets/gfx/d-menu-user-card-role-groups.svg") center
    no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.donors .icon:before {
  background: url("../assets/gfx/menu-user-star-donors.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.donors .icon:hover:before {
  background: url("../assets/gfx/d-menu-user-star-donors.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.databasestatus .icon:before {
  background: url("../assets/gfx/menu-database.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.databasestatus .icon:hover:before {
  background: url("../assets/gfx/d-menu-database.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.import .icon:before {
  background: url("../assets/gfx/menu-arrow-backward-import.svg") center
    no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.import .icon:hover:before {
  background: url("../assets/gfx/d-menu-arrow-backward-import.svg") center
    no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.importhistory .icon:before {
  background: url("../assets/gfx/menu-arrow-backward-import-history.svg") center
    no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.importhistory .icon:hover:before {
  background: url("../assets/gfx/d-menu-arrow-backward-import-history.svg")
    center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.export .icon:before {
  background: url("../assets/gfx/menu-arrow-forward-export.svg") center
    no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.export .icon:hover:before {
  background: url("../assets/gfx/d-menu-arrow-forward-export.svg") center
    no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.exporthistory .icon:before {
  background: url("../assets/gfx/menu-arrow-forward-export-history.svg") center
    no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.exporthistory .icon:hover:before {
  background: url("../assets/gfx/d-menu-arrow-forward-export-history.svg")
    center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.searchhistory .icon:before {
  background: url("../assets/gfx/menu-search-history.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.searchhistory .icon:hover:before {
  background: url("../assets/gfx/d-menu-search-history.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.notifications .icon:before {
  background: url("../assets/gfx/menu-bell.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  /* display: inline-block; */
  height: 50px;
  width: 25px;
  float: left;
  transition: 1s;
  padding: 0 12px;
}

.notifications .icon:hover:before {
  background: url("../assets/gfx/d-menu-bell.svg") center no-repeat;
  background-size: 25px 25px;
  content: "";
  display: inline-block;
  height: 50px;
  width: 25px;
  padding: 0 12px;
}

.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu ul li {
  border-top: 2px solid #fff;
  border-bottom: 1px solid #c9e5fa;
  background: linear-gradient(90deg, #f2f9ff, #edf5fb);
  background-image: -webkit-linear-gradient(90deg, #f2f9ff, #edf5fb);
  background-color: #eef7ff;
  display: block;
  padding: 0 12px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  transition: background 0.5s;
}

.menu ul li:hover {
  background: #d8eeff;
}

.menu ul li a:hover {
  color: #1c233f;
  background: inherit;
  font-weight: bold;
}

.menu ul li:first-child {
  border-top: 0;
}

.menu ul li a {
  text-decoration: none;
  display: block;
  color: #6288ad;
  font-family: "Tahoma", sans-serif;
  font-size: 15px;
  transition: color 1s;
}
</style>

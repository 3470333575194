import httpClient from "./httpClient";
import { AxiosResponse } from "axios";

import AtaEmdReq from "@/model/ataEmdReq";

export default class AtaEmdReqService {
  static getEmdReqs(
    patientId: string,
    donorGrid: string
  ): Promise<AxiosResponse<AtaEmdReq[]>> {
    return httpClient.get(`/ateEmdReqs/${patientId}/${donorGrid}`);
  }

  static postEmdReq(request: AtaEmdReq): Promise<AxiosResponse<AtaEmdReq>> {
    return httpClient.post(`/ataEmdReq/`, request);
  }

  static getEmdReqSample(
    patientId: string,
    donorGrid: string
  ): Promise<AxiosResponse<AtaEmdReq>> {
    return httpClient.get(`/ateEmdReqs/sample/${patientId}/${donorGrid}`);
  }
}

<template>
  <span class="p-float-label">
    <Calendar v-model="date" :disabled="formDef.disabled" />
    <label> {{ formDef.label }} </label>
  </span>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Calendar from "primevue/calendar";
import FormSpecDef from "@/model/forms/formSpecDef";
import AtaTmpPatUtil from "@/util/ataTmpPatUtil";
import FormSpecification from "@/model/forms/formSpecification";
import { namespace } from "vuex-class";
const formProvider = namespace("formProvider");

@Options({
  components: { Calendar },
  props: {
    formDef: Object as () => FormSpecDef
  }
})
export default class DDatePicker extends Vue {
  formDef!: FormSpecDef;
  @formProvider.State
  formSpec!: FormSpecification;

  utcDate(date: Date): Date {
    if (date instanceof Date) {
      date = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
    }
    return date;
  }

  get date() {
    if (!this.formDef.name) return undefined;
    const data = this.formSpec.formData[this.formDef.name];
    if (typeof data === "number") return undefined;
    return AtaTmpPatUtil.parseDate(data);
  }

  set date(value: Date | undefined) {
    if (!this.formDef.name) return;
    this.formSpec.formData[this.formDef.name] = value
      ? this.utcDate(value).toISOString()
      : undefined;
  }
}
</script>

<style scoped></style>

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import User from "@/model/user";
import Roles from "@/model/roles";
import AuthService from "@/service/auth";
import Credentials from "@/model/credentials";

@Module({ namespaced: true })
export default class CurrentUser extends VuexModule {
  user!: User;
  roles!: Roles;
  error!: string;

  @Mutation
  SET_ERROR(error: string) {
    this.error = error;
  }

  @Mutation
  SET_USER(user: User) {
    this.user = user;
  }

  @Mutation
  SET_ROLES(roles: Roles) {
    this.roles = roles;
  }

  @Action
  async fetchUser(cred: Credentials) {
    await AuthService.login(cred)
      .then(resp => {
        if (resp.status == 200) {
          localStorage.setItem(
            "user",
            `Basic ${window.btoa(`${cred.user}:${cred.password}`)}`
          );
          this.context.commit(
            "SET_USER",
            new User(
              cred.user,
              "tmp first name",
              "tmp last name",
              `Basic ${window.btoa(`${cred.user}:${cred.password}`)}`
            )
          );
          this.context.commit("SET_ROLES", resp.data.list);
        } else if (resp.status == 203) {
          localStorage.removeItem("user");
          localStorage.setItem("code", "two-fact-required");
        } else {
          this.context.commit("SET_ERROR", "Error: status: " + resp.status);
          localStorage.removeItem("user");
        }
      })
      .catch(error => {
        if (error.response) {
          this.context.commit("SET_ERROR", error.response.data);
          localStorage.removeItem("user");
        } else {
          this.context.commit(
            "SET_ERROR",
            "Server error: Please contact support atlantaadm@steiner.cz"
          );
          localStorage.removeItem("user");
        }
      });
  }
}

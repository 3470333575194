<template>
  <div :class="'p-mb-4 p-col-12 p-xl-' + formDef.size">
    <component
      v-if="formDef.children && formDef.children.length"
      :is="formDef.type"
      :formDef="formDef"
    >
      <!--      &lt; children {{ formDef.label }} &gt;-->
      <div class="p-grid p-formgrid p-fluid">
        <DField
          :formDef="field"
          v-for="(field, i) in formDef.children"
          :key="i"
        />
      </div>
      <!--      &lt; children {{ formDef.label }} /&gt;-->
    </component>
    <component v-else :is="formDef.type" :formDef="formDef" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import FormSpecDef from "@/model/forms/formSpecDef";
import components from "@/components/dynamic/components";

@Options({
  components,
  props: {
    formDef: Object as () => FormSpecDef
  }
})
export default class DField extends Vue {
  formDef!: FormSpecDef;
}
</script>

<style scoped></style>

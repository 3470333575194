export default class Alert {
  static MAX_UID = 999999;
  static UID = 0;
  value: string;
  field: string;
  id: number;

  constructor(field: string, value: string) {
    this.field = field;
    this.value = value;
    this.id = this.genId();
  }

  genId() {
    if (Alert.UID > Alert.MAX_UID) Alert.UID = 0;
    return Alert.UID++;
  }

  get msg() {
    return this.field ? `${this.field}: ${this.value}` : this.value;
  }
}

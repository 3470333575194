import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import FormService from "@/service/form";
import FormSpecification from "@/model/forms/formSpecification";
import FormSpecDef from "@/model/forms/formSpecDef";
import DFormValidate from "@/util/DFormValidate";

@Module({ namespaced: true })
export default class FormProvider extends VuexModule {
  formSpec!: FormSpecification;
  formError = {} as Record<string, string>;

  @Mutation
  SET_FORM(formSpec: FormSpecification) {
    // console.log(formSpec);
    this.formSpec = formSpec;
  }

  @Action
  async fetchForm() {
    // this.context.commit("SET_FORM", {formData:[],formDef:[]});
    this.context.commit("SET_FORM", FormService.getFromInMemory());
    // await FormService.getForm(name)
    //     .then(resp => {
    //         this.context.commit("SET_FORM", resp.data);
    //     })
    //     .catch(err => console.error(err));
  }

  @Action
  setValue({
    field,
    value
  }: {
    field: FormSpecDef;
    value: string | number | undefined;
  }) {
    if (!field.name) return;
    this.formError[field.name] = DFormValidate.validate(field, value);
    this.formSpec.formData[field.name] = value;
  }

  @Action
  async button(action: string) {
    await FormService.postForm(action, this.formSpec.formData).then(resp => {
      this.context.commit("SET_FORM", resp.data);
    });
  }
}

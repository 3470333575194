import httpClient from "./httpClient";
import { AxiosResponse } from "axios";

import AtaTmpPat from "@/model/ataTmpPat";

export default class Bmdpat1Service {
  static getPatientsPage(size: number, page: number, filter = "", sort = "") {
    return httpClient.page<AtaTmpPat>(
      "/bmdpat1s-page",
      size,
      page,
      filter,
      sort
    );
  }

  static bmdpatexistPersNumber(
    persnumber: string
  ): Promise<AxiosResponse<AtaTmpPat>> {
    return httpClient.get(`/bmdpatexist/${persnumber}`);
  }

  static bmdpatExistTNumber(
    patientid1: string
  ): Promise<AxiosResponse<AtaTmpPat>> {
    return httpClient.get(`/bmdpatexistbypatientid1/${patientid1}`);
  }
}

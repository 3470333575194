<template>
  <slot />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class SimpleLayout extends Vue {}
</script>

<style scoped></style>

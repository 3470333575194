import Page from "@/model/page";
import axios, { AxiosRequestConfig } from "axios";

const httpClient = {
  ...axios.create({
    baseURL: process.env.VUE_APP_REST_URL,
    timeout: 5000
  }),
  page: <T>(
    url: string,
    size: number,
    page: number,
    filter = "",
    sort = ""
  ) => {
    let query = `${url}?page=${page}&size=${size}`;
    if (filter.length > 0) query += `&filter=${filter}`;
    if (sort.length > 0) query += `&order=${sort}`;
    return httpClient.get<Page<T>>(query);
  }
};

const authInterceptor = (config: AxiosRequestConfig) => {
  config.headers["Authorization"] = localStorage.getItem("user");
  return config;
};

httpClient.interceptors.request.use(authInterceptor);
// httpClient.interceptors.response.use(undefined, err => {
//   console.log("interceptors: " + err);
//   return Promise.reject(err);
// });

export default httpClient;

import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import PrimeVue from "primevue/config";

import Tooltip from "primevue/tooltip";
import ViewTemplate from "@/components/ViewTemplate.vue";
import Button from "primevue/button";
import BadgeDirective from "primevue/badgedirective";
import ToastService from "primevue/toastservice";
import DField from "@/components/dynamic/DField.vue";

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

createApp(App)
  .use(PrimeVue)
  .use(router)
  .use(store)
  .use(ToastService)
  .directive("tooltip", Tooltip)
  .directive("badge", BadgeDirective)
  .component("ViewTemplate", ViewTemplate)
  .component("Button", Button)
  .component("DField", DField)
  .mount("#app");

import { Component } from "vue";

/**
 * Automatically imports all the components and exports as a single components object
 */
const requireModule = require.context(".", false, /\.vue$/);
const components: { [id: string]: Component } = {};

requireModule.keys().forEach(filename => {
  const moduleName = filename.replace(/(\.\/|\.vue)/g, "");

  components[moduleName] =
    requireModule(filename).default || requireModule(filename);
});

export default components;

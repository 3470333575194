<template>
  <span v-if="formDef.name" class="p-float-label">
    <InputText
      v-model="value"
      :disabled="formDef.disabled"
      :class="{ 'p-invalid': formError[formDef.name] }"
      v-tooltip.focus="formError[formDef.name]"
      type="text"
    />
    <label> {{ formDef.label }} </label>
  </span>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import InputText from "primevue/inputtext";
import FormSpecDef from "@/model/forms/formSpecDef";
import FormSpecification from "@/model/forms/formSpecification";
import { namespace } from "vuex-class";
const formProvider = namespace("formProvider");

@Options({
  components: {
    InputText
  },
  props: {
    formDef: Object as () => FormSpecDef
  }
})
export default class DInput extends Vue {
  // props
  formDef!: FormSpecDef;

  get value() {
    return this.formSpec.formData[this.formDef.name ? this.formDef.name : ""];
  }

  set value(v) {
    this.setValue({ field: this.formDef, value: v });
  }

  // Vuex
  @formProvider.State
  formSpec!: FormSpecification;
  @formProvider.State
  formError!: Record<string, string>;

  @formProvider.Action
  setValue!: ({
    field,
    value
  }: {
    field: FormSpecDef;
    value: string | number | undefined;
  }) => void;
}
</script>

<style scoped></style>

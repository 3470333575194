<template>
  <h2>{{ formDef.label }}</h2>
  <slot />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import FormSpecDef from "@/model/forms/formSpecDef";

@Options({
  props: {
    formDef: Object as () => FormSpecDef
  }
})
export default class DGroup extends Vue {
  formDef!: FormSpecDef;
}
</script>

<style scoped>
h2 {
  font-weight: bold;
  font-size: 30px;
}
</style>

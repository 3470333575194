
import { Options, Vue } from "vue-class-component";
import FormSpecDef from "@/model/forms/formSpecDef";

@Options({
  props: {
    formDef: Object as () => FormSpecDef
  }
})
export default class DGroup extends Vue {
  formDef!: FormSpecDef;
}

import httpClient from "./httpClient";

import AtaTmpPat from "@/model/ataTmpPat";

export default class AtaTmpPatService {
  static postPatient(patient: AtaTmpPat) {
    return httpClient.post(`/ataTmpPat/`, patient);
  }

  static getPatientsPage(size: number, page: number, filter = "", sort = "") {
    return httpClient.page<AtaTmpPat>(
      "/ataTmpPats-page",
      size,
      page,
      filter,
      sort
    );
  }
}

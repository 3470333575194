<template>
  <TabView v-if="formDef.tabs && formDef.tabs.length" class="p-mb-3 p-shadow-1">
    <TabPanel
      :header="tab.label"
      :disabled="tab.disabled"
      v-for="(tab, i) in formDef.tabs"
      :key="i"
    >
      <div class="p-mb-4" />
      <div
        v-if="tab.children && tab.children.length"
        class="p-grid p-formgrid p-fluid"
      >
        <DField :formDef="tabC" v-for="(tabC, i) in tab.children" :key="i" />
      </div>
    </TabPanel>
  </TabView>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import FormSpecDef from "@/model/forms/formSpecDef";
import FormSpecification from "@/model/forms/formSpecification";
import { namespace } from "vuex-class";
const formProvider = namespace("formProvider");

@Options({
  components: {
    TabView,
    TabPanel
  },
  props: {
    formDef: Object as () => FormSpecDef
  }
})
export default class DTabs extends Vue {
  formDef!: FormSpecDef;
  @formProvider.State
  formSpec!: FormSpecification;
}
</script>

<style scoped></style>

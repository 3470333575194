export default class User {
  private _email: string;
  private _firstName: string;
  private _lastName: string;
  private _credentials: string;

  constructor(
    email: string,
    firstName: string,
    lastName: string,
    credentaials: string
  ) {
    this._email = email;
    this._firstName = firstName;
    this._lastName = lastName;
    this._credentials = credentaials;
  }

  get email(): string {
    return this._email;
  }

  get credentials(): string {
    return this._credentials;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }
}

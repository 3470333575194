
import { Options, Vue } from "vue-class-component";
import HeaderToolbar from "@/components/HeaderToolbar.vue";
import Navigation from "@/components/Navigation.vue";
import AutoLogout from "@/components/AutoLogout.vue";
import Pinger from "@/components/Pinger.vue";

@Options({
  components: {
    HeaderToolbar,
    Navigation,
    AutoLogout,
    Pinger
  }
})
export default class DefaultLayout extends Vue {}

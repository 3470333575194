<template>
  <Toast group="pinger" position="bottom-right" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Ping } from "@/service/ping";
import Toast from "primevue/toast";

@Options({
  components: {
    Toast
  }
})
export default class Pinger extends Vue {
  pingTimer: number | undefined = undefined;
  down = false;

  clearTimer() {
    this.down = false;
    this.$toast.removeGroup("pinger");
    clearTimeout(this.pingTimer);
  }

  setTimer() {
    this.pingTimer = setTimeout(this.ping, (this.down ? 3 : 10) * 1000);
  }

  ping() {
    Ping.ping()
      .then(() => {
        this.down = false;
        this.$toast.removeGroup("pinger");
      })
      .catch(() => {
        if (!this.down)
          this.$toast.add({
            severity: "warn",
            summary: "Server Down",
            detail: "Server is down",
            closable: false,
            group: "pinger"
          });
        this.down = true;
      });
    this.setTimer();
  }

  //   logout() {
  //     Auth.logout();
  //     this.$router.push("/login-form");
  //   }

  mounted() {
    this.setTimer();
  }

  unmounted() {
    this.clearTimer();
  }
}
</script>

<style scoped></style>

import EnumValue from "@/model/enums";

export default class Enum {
  static get values(): EnumValue[] {
    return Object.values(this);
  }

  static byCode(code: EnumValue["code"]) {
    return this.values.find(g => g.code == code)?.name;
  }
}

import { createWebHistory, createRouter } from "vue-router";
import SimpleLayout from "@/layout/SimpleLayout.vue";

const routes = [
  {
    path: "/",
    alias: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "./views/Dashboard.vue")
  },
  {
    path: "/login-form",
    name: "login-form",
    meta: { layout: SimpleLayout, requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "login" */ "./views/LoginForm.vue")
  },
  {
    path: "/test",
    name: "test",
    component: () => import(/* webpackChunkName: "test" */ "./views/Test.vue")
  },
  {
    path: "/patient-list",
    name: "patient-list",
    component: () =>
      import(/* webpackChunkName: "patientList" */ "./views/PatientList.vue")
  },
  {
    path: "/add-patient",
    name: "add-patient",
    component: () =>
      import(/* webpackChunkName: "addPatient" */ "./views/AddPatient.vue")
  },
  {
    path: "/recommended-donors",
    name: "recommended-donors",
    component: () =>
      import(
        /* webpackChunkName: "recommendedDonors" */ "./views/RecommendedDonors.vue"
      )
  },
  {
    path: "/user-settings",
    name: "user-settings",
    component: () =>
      import(/* webpackChunkName: "userSettings" */ "./views/UserSettings.vue")
  },
  {
    path: "/form-test",
    name: "form-test",
    component: () =>
      import(/* webpackChunkName: "userSettings" */ "./views/DFormsTest.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login-form"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next({
      path: "/login-form",
      query: { returnUrl: to.path }
    });
  }

  next();
});

export default router;

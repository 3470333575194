<template>
  <header>
    <div class="topheader">
      <div class="hamburger" @click="toggle">
        <div><img src="gfx/d-menu.svg" /> MENU</div>
      </div>
      <div class="logo">
        <img src="veripalvelu_englanti.png" />
      </div>
      <div class="userBox">
        <i
          class="pi pi-bell p-mr-4 p-text-secondary"
          style="font-size: 25px"
          v-badge="2"
          @click="showMessage"
        />
        <i
          class="pi pi-envelope p-mr-4 p-text-secondary"
          style="font-size: 25px; "
          v-badge.danger
          @click="overlay"
        />
        <i
          @click="settings"
          style="font-size: 25px"
          class="pi pi-cog p-text-secondary"
        />
        <Button
          @click="logout"
          style="margin-left: 10px;"
          class="p-button-rounded"
        >
          Logout
        </Button>
        <OverlayPanel ref="op">
          Messages not implemented yet.
        </OverlayPanel>
      </div>
      <div class="cleaner"></div>
    </div>
  </header>
  <Toast />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AuthService from "@/service/auth";
import Badge from "primevue/badge";
import Toast from "primevue/toast";
import OverlayPanel from "primevue/overlaypanel";
import { namespace } from "vuex-class";
const navMenu = namespace("navMenu");

@Options({ components: { Badge, Toast, OverlayPanel } })
export default class HeaderToolbar extends Vue {
  declare $refs: {
    op: HTMLFormElement;
  };
  @navMenu.Mutation
  toggle!: () => void;

  logout() {
    AuthService.logout();
    this.$router.push("/login-form");
  }

  settings() {
    this.$router.push({ name: "user-settings" });
  }

  showMessage() {
    this.$toast.add({
      severity: "success",
      summary: "Bell clicked",
      detail: "Placeholder",
      life: 3000
    });
  }

  overlay(event: object) {
    this.$refs.op.toggle(event);
  }
}
</script>

<style scoped>
.topheader {
  border-bottom: 1px solid #e3e2e6;
  height: 84px;
}
.hamburger {
  /*width:120px;*/
  height: 60px;
  float: left;
}
.hamburger div {
  color: #1c233f;
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  line-height: 60px;
  padding: 0 12px 0 24px;
}
.logo {
  margin: 0 15px;
  padding: 5px 25px;
  border-left: 1px solid #e3e2e6;
  -webkit-background-size: 100px 160px;
  background-size: 100px 160px;
  float: left;
}
.logo img {
  max-height: 50px;
}
.userBox {
  float: right;
  margin-top: 15px;
}
.circle {
  height: 36px;
  width: 36px;
  background-color: #f3f4f9;
  background-size: 36px 36px;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
}
.circle:hover {
  background: #e3e3e3;
}
a.circleLink {
  /* display: inline-block; */
  padding-left: 40px;
  vertical-align: middle;
  line-height: 36px;
  font-size: 12px;
  text-decoration: none;
  color: #919191;
  margin-left: 0;
  float: left;
  width: max-content;
}
.circleLink {
  /*min-width: 120px;*/
  height: 36px;
  float: right;
}
.circleIconUser {
  background: url("../assets/gfx/user.svg") no-repeat;
  background-size: 36px 36px;
  content: "";
  /* display: inline-block; */
  height: 36px;
  width: 36px;
  float: left;
  z-index: 33;
}
.user {
  float: left;
  padding-right: 120px;
  /*border:1px solid red;*/
}
.notification {
  float: left;
}
.message {
  float: left;
}
.circleIconNotificate {
  background: url("../assets/gfx/bell-1.svg") no-repeat;
  background-size: 36px 36px;
  content: "";
  /* display: inlie-block; */
  height: 36px;
  width: 36px;
  float: left;
  z-index: 33;
}
.circleIconMessages {
  background: url("../assets/gfx/message-0.svg") no-repeat;
  background-size: 36px 36px;
  content: "";
  /* display: inline-block; */
  height: 36px;
  width: 36px;
  float: left;
  z-index: 33;
}
</style>

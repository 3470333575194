import FormSpecDef from "@/model/forms/formSpecDef";

export default class DFormValidate {
  static validate(
    field: FormSpecDef,
    value: string | number | undefined
  ): string {
    if (field.validator)
      for (const v of field.validator) {
        switch (v.type.toLowerCase()) {
          case "required":
            if (!this.validateRequired(value)) {
              return v.message || "Required";
            }
            break;
          case "regexp":
            if (
              !this.isEmpty(value) &&
              v.regexp &&
              !this.validateRegexp(v.regexp, this.getString(value))
            ) {
              return v.message || "Wrong format";
            }
            break;
          case "date":
            if (
              !this.isEmpty(value)
              // &&
              // !this.validateDate(
              //   {
              //     min: v.min,
              //     max: v.max
              //   },
              //   this.getString(value)
              // )
            ) {
              return v.message || "Wrong date";
            }
            break;
        }
      }
    return "";
  }

  static getString(value: string | number | undefined): string {
    return typeof value == "string" ? value : "";
  }

  static isEmpty(value: string | number | undefined) {
    return typeof value === "undefined" || value === "" || value === null;
  }

  static validateRequired(value: string | number | undefined): boolean {
    return !this.isEmpty(value);
  }

  static validateRegexp(regexp: string, value: string) {
    const re = new RegExp(regexp);
    return re.test(value);
  }

  // static validateDate(
  //   { max, min }: { min?: string; max?: string },
  //   value: string
  // ) {
  // // TODO
  // const date = moment(value);
  // if (!date.isValid()) {
  //   return false;
  // }
  // if (min && date.valueOf() < moment(min).valueOf()) {
  //   return false;
  // }
  // if (max && date.valueOf() > moment(max).valueOf()) {
  // return false;
  // }
  // return true;
  // }
}

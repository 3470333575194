
import { Options, Vue } from "vue-class-component";
import AuthService from "@/service/auth";
import Badge from "primevue/badge";
import Toast from "primevue/toast";
import OverlayPanel from "primevue/overlaypanel";
import { namespace } from "vuex-class";
const navMenu = namespace("navMenu");

@Options({ components: { Badge, Toast, OverlayPanel } })
export default class HeaderToolbar extends Vue {
  declare $refs: {
    op: HTMLFormElement;
  };
  @navMenu.Mutation
  toggle!: () => void;

  logout() {
    AuthService.logout();
    this.$router.push("/login-form");
  }

  settings() {
    this.$router.push({ name: "user-settings" });
  }

  showMessage() {
    this.$toast.add({
      severity: "success",
      summary: "Bell clicked",
      detail: "Placeholder",
      life: 3000
    });
  }

  overlay(event: object) {
    this.$refs.op.toggle(event);
  }
}

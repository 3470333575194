<template>
  <h1>
    <img src="../assets/gfx/d-menu-user-star-donors.svg" height="30" />
    {{ title }}
  </h1>
  <slot />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    title: String
  }
})
export default class ViewTemplate extends Vue {}
</script>

<style scoped>
h1 {
  display: block;
  /*padding: 35px 0 15px;*/
  font-weight: normal;
  font-size: 30px;
  /*margin-bottom: 7px;*/
  border-bottom: 3px solid #1c233f;
  font-family: "Tahoma", sans-serif;
  color: #1c233f;
  /*font-size: 2em;*/
  /*margin-block-start: 0.67em;*/
  /*margin-block-end: 0.67em;*/
  /*margin-inline-start: 0px;*/
  /*margin-inline-end: 0px;*/
}
</style>

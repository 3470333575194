import httpClient from "./httpClient";
import Credentials from "@/model/credentials";
import { AxiosResponse } from "axios";

import Roles from "@/model/roles";

export default class AuthService {
  static async login(cred: Credentials): Promise<AxiosResponse<Roles>> {
    return httpClient.post(`/authentificaiton`, cred);
  }

  static async googleAuth(): Promise<AxiosResponse<string>> {
    return httpClient.get(`/twoFactorAuthen`, { responseType: "arraybuffer" });
  }

  static logout(): void {
    localStorage.removeItem("user");
  }
}

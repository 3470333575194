<template>
  <!-- FIXME Header messing up with grid on small sizes -->
  <AutoLogout />
  <Pinger />
  <HeaderToolbar />
  <div class="p-grid">
    <div class="p-col-fixed">
      <Navigation />
    </div>
    <div class="p-col">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HeaderToolbar from "@/components/HeaderToolbar.vue";
import Navigation from "@/components/Navigation.vue";
import AutoLogout from "@/components/AutoLogout.vue";
import Pinger from "@/components/Pinger.vue";

@Options({
  components: {
    HeaderToolbar,
    Navigation,
    AutoLogout,
    Pinger
  }
})
export default class DefaultLayout extends Vue {}
</script>

<style scoped></style>

<template>
  <span v-if="formDef.name" class="p-float-label">
    <Dropdown
      v-model="formSpec.formData[formDef.name]"
      :options="formDef.options"
      optionLabel="label"
      optionValue="value"
      :disabled="formDef.disabled"
    />
    <label> {{ formDef.label }} </label>
  </span>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Dropdown from "primevue/dropdown";
import FormSpecDef from "@/model/forms/formSpecDef";
import FormSpecification from "@/model/forms/formSpecification";
import { namespace } from "vuex-class";
const formProvider = namespace("formProvider");

@Options({
  components: { Dropdown },
  props: {
    formDef: Object as () => FormSpecDef
  }
})
export default class DSelect extends Vue {
  formDef!: FormSpecDef;
  @formProvider.State
  formSpec!: FormSpecification;
}
</script>

<style scoped></style>

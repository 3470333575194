
import { Options, Vue } from "vue-class-component";
import HeaderToolbar from "@/components/HeaderToolbar.vue";
import Navigation from "@/components/Navigation.vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";

@Options({
  components: {
    HeaderToolbar,
    Navigation
  }
})
export default class App extends Vue {
  version: string = process.env.VUE_APP_VERSION;

  get layout() {
    return this.$route.meta.layout ? this.$route.meta.layout : DefaultLayout;
  }
}

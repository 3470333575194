import httpClient from "./httpClient";
import { AxiosResponse } from "axios";

import FormSpecification from "@/model/forms/formSpecification";

export default class FormService {
  static getForm(name: string): Promise<AxiosResponse<FormSpecification>> {
    return httpClient.get(`/api/form/` + name);
  }

  static postForm(
    action: string,
    data: FormSpecification["formData"]
  ): Promise<AxiosResponse<FormSpecification>> {
    console.log(`/api/form/` + action);
    console.log(data);
    return {} as Promise<AxiosResponse<FormSpecification>>;
    //return httpClient.post(`/api/form/` + name, data);
  }

  static getFromInMemory(): FormSpecification {
    return {
      formData: {
        firstName: "Pepa",
        lastname: "",
        titul: "prof. MUdr.",
        birthname: "Pavel",
        birthdate: "2021-04-07T00:00:00.000Z",
        select: "1",
        ahoj: "AHOJ"
      },
      formDef: [
        {
          type: "DTabs",
          tabs: [
            {
              type: "",
              label: "Patient",
              children: [
                {
                  type: "DGroup",
                  label: "Externi údaje",
                  children: [
                    {
                      type: "DBox",
                      label: "Patient",
                      children: [
                        {
                          type: "DInput",
                          label: "Titul",
                          name: "titul",
                          size: 2,
                          validator: [
                            {
                              type: "required"
                            }
                          ]
                        },
                        {
                          type: "DInput",
                          label: "Jméno",
                          name: "firstName",
                          size: 5
                        },
                        {
                          type: "DInput",
                          label: "Přímení",
                          name: "lastname",
                          size: 5
                        },
                        {
                          type: "DInput",
                          label: "Rodinné jméno",
                          name: "birthname",
                          size: 6
                        },
                        {
                          type: "DDatePicker",
                          label: "Birthdate",
                          name: "birthdate",
                          size: 6
                        },
                        {
                          type: "DSelect",
                          label: "Select",
                          name: "select",
                          options: [
                            { value: "1", label: "One" },
                            { value: "2", label: "Two" }
                          ],
                          size: 6
                        },
                        {
                          type: "DTabs",
                          size: 6,
                          tabs: [
                            {
                              type: "",
                              label: "A",
                              children: [
                                {
                                  type: "DInput",
                                  label: "ahoj",
                                  name: "ahoj",
                                  size: 6
                                },
                                {
                                  type: "DInput",
                                  label: "ahoj2",
                                  name: "ahoj",
                                  size: 6
                                }
                              ]
                            },
                            {
                              type: "",
                              label: "B",
                              children: [
                                {
                                  type: "DInput",
                                  label: "ahoj2",
                                  name: "ahoj",
                                  size: 12
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: "",
              label: "Donor",
              children: [
                {
                  type: "DInput",
                  label: "ahoj2",
                  name: "ahoj",
                  size: 12
                }
              ]
            }
          ]
        },
        {
          type: "DButton",
          action: "ahoj1",
          label: "Post",
          size: 1
        }
      ]
    };
  }
}

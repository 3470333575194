<template>
  <div style="min-height: 92vh;">
    <component :is="layout">
      <router-view />
    </component>
  </div>
  <div id="footer">Version: {{ version }}</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HeaderToolbar from "@/components/HeaderToolbar.vue";
import Navigation from "@/components/Navigation.vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";

@Options({
  components: {
    HeaderToolbar,
    Navigation
  }
})
export default class App extends Vue {
  version: string = process.env.VUE_APP_VERSION;

  get layout() {
    return this.$route.meta.layout ? this.$route.meta.layout : DefaultLayout;
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0 5px 0 5px;
}

#footer {
  margin: 10px;
  text-align: center;
}
</style>

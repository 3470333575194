import Enum from "@/util/enum";
import EnumValue from ".";

class Ethnicity extends Enum {
  static AF: EnumValue = { code: 9, name: "African" }; // 09
  static AFNA: EnumValue = { code: 10, name: "African: North Africa" }; // 10
  static AFSS: EnumValue = { code: 11, name: "African: Sub-Sahara Africa" }; // 11
  static AS: EnumValue = { code: 12, name: "Asian" }; // 12
  static ASSW: EnumValue = {
    code: 13,
    name: "Asian: Southwest Asia (Middle East, Turkey)"
  }; // 13
  static ASSO: EnumValue = {
    code: 14,
    name:
      "Asian: Southern Asia (India, Pakistan, Bangladesh, Sri Lanka, Bhutan, Nepal)"
  }; // 14
  static ASCE: EnumValue = {
    code: 15,
    name:
      "Asian: Central Asia (Eastern Russia, Kazakhstan, Uzbekistan, Kyrgyzstan, Tajikistan)"
  }; // 15
  static ASSE: EnumValue = {
    code: 16,
    name:
      "Asian: Southeast Asia (China, Mongolia, Burma, Laos, Cambodia, Thailand, Vietnam, Taiwan)"
  }; // 16
  static ASNE: EnumValue = {
    code: 17,
    name: "Asian: North and Northeast Asia (Japan, North Korea, South Korea)"
  }; // 17
  static ASOC: EnumValue = {
    code: 18,
    name:
      "Asian: Oceania (Pacific Islands excluding Japan, Australia, Taiwan, Sakhalin, Aleutian Islands)"
  }; // 18
  static CA: EnumValue = { code: 19, name: "Caucasian" }; // 19
  static CAEU: EnumValue = {
    code: 20,
    name: "Caucasian: Mainland Europe, Greenland, Iceland,   Western Russia"
  }; // 20
  static CAER: EnumValue = { code: 21, name: "Caucasian: Eastern Russia" }; // 21
  static CANA: EnumValue = {
    code: 22,
    name: "Caucasian: North America (USA, Canada, Mexico)"
  }; // 22
  static CAAU: EnumValue = {
    code: 23,
    name: "Caucasian: Australia (Australia, New Zealand)"
  }; // 23
  static HI: EnumValue = { code: 24, name: "Hispanic" }; // 24
  static HICA: EnumValue = {
    code: 25,
    name: "Hispanic: Central America, Caribbean"
  }; // 25
  static HISA: EnumValue = { code: 26, name: "Hispanic: South America" }; // 26
  static MX: EnumValue = { code: 27, name: " Mixed / multiple" }; // 27
  static OT: EnumValue = {
    code: 28,
    name: "Other (e.g. Australian Aborigine)"
  }; // 28
  static UK: EnumValue = { code: 29, name: "Unknown" }; // 29
}

export default Ethnicity;

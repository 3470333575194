import httpClient from "./httpClient";
import { AxiosResponse } from "axios";

import RecommendedDonor from "@/model/recommendedDonor";
import MoreDonorResponse from "@/model/moreDonorResponse";

export default class RecommendedDonorsService {
  static getEmddon1(
    patientid: string
  ): Promise<AxiosResponse<RecommendedDonor[]>> {
    return httpClient.get(`/redommended/${patientid}`);
  }

  static moreDonorList(
    patientnum: string
  ): Promise<AxiosResponse<MoreDonorResponse[]>> {
    return httpClient.get(`/moreDonorList/${patientnum}`);
  }

  static moreDonor(id: number): Promise<AxiosResponse<string>> {
    return httpClient.get(`/moreDonor/${id}`, {
      responseType: "blob"
    });
  }
}

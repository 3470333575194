<template>
  <Toast group="autologout" position="bottom-right" />
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import Auth from "@/service/auth";
import Toast from "primevue/toast";

@Options({
  components: {
    Toast
  }
})
export default class AutoLogout extends Vue {
  private events: string[] = [
    "click",
    "mousemove",
    "mousedown",
    "scroll",
    "keypress",
    "load"
  ];
  private warningTimer: number | undefined = undefined;
  private logoutTimer: number | undefined = undefined;
  private warningZone = false;

  private clearTimer() {
    this.$toast.removeGroup("autologout");
    clearTimeout(this.warningTimer);
    clearTimeout(this.logoutTimer);
  }

  private setTimer() {
    this.warningTimer = setTimeout(this.warningMessage, 14 * 60 * 1000); //14 min
    this.logoutTimer = setTimeout(this.logout, 15 * 60 * 1000); // 15 min
    this.warningZone = false;
  }

  private resetTimer() {
    this.clearTimer();
    this.setTimer();
  }

  private warningMessage() {
    this.warningZone = true;
    this.$toast.add({
      severity: "warn",
      summary: "Autologout",
      detail: "You will be logged out due to inactivity in one minute",
      closable: false,
      group: "autologout"
    });
  }

  private logout() {
    Auth.logout();
    this.$router.push("/login-form");
  }

  mounted() {
    this.events.forEach(
      value => window.addEventListener(value, this.resetTimer),
      this
    );
    this.setTimer();
  }

  unmounted() {
    this.events.forEach(
      value => window.removeEventListener(value, this.resetTimer),
      this
    );
    this.clearTimer();
  }
}
</script>
